<template>
  <span>
    <modal-novo-painel :type="'not'"></modal-novo-painel>
    <div class="container h-100 align-items-center animationOpacity">
      <svg
        class="wave wave01"
        width="555"
        height="460"
        viewBox="0 0 555 460"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.0823674 388.522C-53.6566 359.613 -85.103 321.972 -84.4282 283.358C-84.2239 244.857 -51.4279 205.268 -46.2004 164.885C-40.579 124.07 -62.1323 82.0283 -47.2524 38.3134C-32.4491 -5.72038 19.1814 -51.8593 80.6096 -69.6588C142.432 -87.8903 213.975 -78.1013 277.486 -60.3104C340.921 -42.8383 395.458 -16.8193 442.183 20.1846C489.454 57.3944 528.519 106.021 477.773 133.059C426.951 159.777 286.395 165.226 225.812 215.54C165.23 265.854 184.698 361.353 156.531 399.492C128.288 437.311 53.2741 417.226 0.0823674 388.522Z"
          fill="#002363"
          fill-opacity="0.05"
        />
      </svg>
      <svg
        class="wave wave02"
        width="502"
        height="370"
        viewBox="0 0 502 370"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M553.488 71.8322C607.748 99.7511 639.879 136.808 639.913 175.428C640.415 213.927 608.35 254.11 603.864 294.582C598.993 335.494 621.313 377.133 607.238 421.114C593.244 465.412 542.469 512.49 481.377 531.413C419.9 550.775 348.189 542.3 284.362 525.677C220.618 509.371 165.613 484.357 118.217 448.216C70.2711 411.88 30.3212 363.978 80.5625 336.014C130.886 308.368 271.319 300.342 330.968 248.925C390.618 197.508 369.402 102.382 396.864 63.7336C424.409 25.4024 499.779 44.109 553.488 71.8322Z"
          fill="#002363"
          fill-opacity="0.05"
        />
      </svg>

      <div class="row justify-content-center mt-5">
        <div class="col-5 mt-5 login">
          <svg
            @click="toLogin"
            style="cursor:pointer"
            width="28"
            height="16"
            viewBox="0 0 28 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.00004 8L8.00004 1M26.2961 8L1.00004 8L26.2961 8ZM1.00004 8L8.00004 15L1.00004 8Z"
              stroke="#B5B9C5"
              stroke-width="1.7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="headerLogin">
            <img src="@/assets/icons/logo-fake.svg" />
          </div>
          <div class="headerLogin mt-3 mb-3">
            <div class="title">Seu Dominio ainda não foi Aprovado!</div>
          </div>
          <div class="spaceBtn">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque id risus molestie, malesuada nibh a, efficitur odio.
              Nulla fermentum magna sed pharetra scelerisque. Phasellus in
              tempor purus, vitae dapibus quam. Praesent vel felis neque.
              Maecenas est velit, lacinia non pellentesque sed, tristique non
              tellus. Aliquam vel felis a nulla placerat suscipit eget vitae
              dolor. Vivamus id enim placerat, pellentesque orci eget, pulvinar
              enim.
            </p>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import ModalNovoPainel from "@/components/ModalNovoPainel.vue";

export default {
  components: {
    ModalNovoPainel,
  },
  data() {
    return {
      client: {
        width: 0,
      },
      logo: null,
      color: null,
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.$root.$emit("loadTheme");
  },
  methods: {
    toLogin() {
      this.$router.push("/");
    },
    openCreate() {
      this.$root.$emit("bv::show::modal", "modal-new-painel", "#btnShow");
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.spaceBtn {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2vw;
}
.btn-Acesso {
  background: var(--maincolor);
  border-radius: 30px;
  padding: 14px 28px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: none;
  outline: none;
  transition: 0.3s;
  border-radius: 30px;
}

input {
  border: solid 1px var(--bordercolor);
  box-sizing: border-box;
  border-radius: 30px;
  height: 60px;
  font-size: 14px;
  padding: 20px;
}
input:hover,
input:focus {
  border: 1px solid var(--maincolor);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  background: rgba(0, 228, 160, 0.05);
  color: #333;
  font-weight: normal;
}
input:focus {
  font-weight: 600;
}
.labelxx {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.headerLogin {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
  }
}
.login {
  border-radius: 30px;
  padding: 10px;
}

.h-100 {
  height: 100vh;
}
.wave {
  position: absolute;
  z-index: -1;
  path {
    fill: var(--maincolor) !important;
  }
}
.wave01 {
  top: 0;
  left: 0;
}
.wave02 {
  bottom: 0;
  right: 0;
}
.btn-login {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recuperar-senha {
  color: var(--maincolor);
  font-weight: 600;
}
</style>
